<template>
  <header class="header">
    <router-link to="/" class="header__logo">
      <img src="@/assets/logo.png" alt="" />
    </router-link>
    <div class="header__box">
      <ul class="header__links">
        <li v-for="item of list" :key="item._id">
          <router-link :to="`/${item.page.slug}`">
            <el-icon>
              <el-icon><component :is="item.icon" /></el-icon>
            </el-icon>
            <div class="title">{{ item.page.title }}</div>
          </router-link>
        </li>
        <li>
          <router-link to="/message">
            <el-icon>
              <message />
            </el-icon>
            <div class="title">Xabarlar</div>
          </router-link>
        </li>

        <!-- <li>
          <router-link to='/reports'>
            <el-icon>
              <Files />
            </el-icon>
            <div class="title">Shartnomalar</div>
          </router-link>
        </li> -->
      </ul>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      nav: [
        {
          slug: "bak",
          icon: "DataAnalysis",
        },
        {
          slug: "report",
          icon: "dataLine",
        },
        {
          slug: "students",
          icon: "user",
        },
        {
          slug: "masters",
          icon: "avatar",
        },
        {
          slug: "exam",
          icon: "calendar",
        },
        {
          slug: "reports",
          icon: "files",
        },
        {
          slug: "testproccess",
          icon: "message-box",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user", "menu", "pages"]),
    list() {
      let arr = this.nav.filter((item) => {
        let page = this.menu.find((m) => m.page.slug == item.slug);
        if (page) {
          item.page = page.page;

          return item;
        } else {
          return false;
        }
      });
      return arr;
    },
  },
  methods: {
    ...mapActions(["getPages"]),
  },
  mounted() {
    this.getPages();
  },
};
</script>

<style lang="scss">
@import "@/styles/part/header.scss";
</style>
