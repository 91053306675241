import { ElMessage } from "element-plus";

export const message = {
  state: () => ({
    messagesCount: 0,
    messages: [],
  }),
  getters: {
    messagesCount({ messagesCount }) {
      return messagesCount;
    },
    messages({ messages }) {
      return messages;
    },
  },
  mutations: {
    messages(state, payload) {
      state.messages = payload;
    },
    messagesCount(state, payload) {
      state.messagesCount = payload;
    },
    addMessage(state, payload) {
      state.messages = [payload, ...state.messages];
    },
    deleteMessage(state, payload) {
      state.messages = state.messages.filter((message) => {
        if (message._id === payload) return false;
        return message;
      });
    },
  },

  actions: {
    async getMessages({ dispatch, commit }, payload) {
      let res = await dispatch("getAxios", {
        url: "message",
        search: payload,
      });
      console.log(res.data);
      commit("messages", [...res.data.message]);
      commit("messagesCount", res.data.count);
    },

    getMessage({ dispatch }, payload) {
      return dispatch("getAxios", {
        url: `message/get/${payload}`,
      });
    },

    async addMessage({ dispatch, commit }, payload) {
      let res = await dispatch("postAxios", {
        url: "message",
        data: payload,
      });
      if (res.status === 201) {
        commit("addMessage", { ...res.data });
        ElMessage({
          message: "Yangi xabar qo`shildi",
          type: "success",
        });
      }
    },

    async sendMessage({ dispatch }, id) {
      let res = await dispatch("getAxios", {
        url: `message/send/${id}`,
      });
      if (res.status == 200) {
        ElMessage({
          message: "Xabar jo`natilish boshlandi",
          type: "success",
        });
      }
    },
  },
};
