<template>
  <header class="header">
    <router-link to="/" class="header__logo">
      <img src="@/assets/logo.png" alt="" />
    </router-link>
    <div class="header__box">
      <ul class="header__links">
        <li>
          <router-link to="/bak">
            <el-icon>
              <DataAnalysis />
            </el-icon>
            <div class="title">Statistika</div>
          </router-link>
        </li>
        <li>
          <router-link to="/report">
            <el-icon>
              <DataLine />
            </el-icon>
            <div class="title">Hisobot</div>
          </router-link>
        </li>
        <li>
          <router-link to="/students">
            <el-icon>
              <User />
            </el-icon>
            <div class="title">Bakalavriat</div>
          </router-link>
        </li>
        <li>
          <router-link to="/masters">
            <el-icon>
              <Avatar />
            </el-icon>
            <div class="title">Magistratura</div>
          </router-link>
        </li>
        <li>
          <router-link to="/exam">
            <el-icon>
              <calendar />
            </el-icon>
            <div class="title">Imtihon</div>
          </router-link>
        </li>
        <li>
          <router-link to="/message">
            <el-icon>
              <message />
            </el-icon>
            <div class="title">Xabarlar</div>
          </router-link>
        </li>
        <li>
          <router-link to="/reports">
            <el-icon>
              <Files />
            </el-icon>
            <div class="title">Shartnomalar</div>
          </router-link>
        </li>
        <li>
          <router-link to="/testproccess">
            <el-icon>
              <message-box />
            </el-icon>
            <div class="title">Test jarayon</div>
          </router-link>
        </li>
        <!-- <li>
                <router-link to='/payments'>
                <el-icon><Files /></el-icon>
                <div class="title">To'lovlar</div>
                </router-link>
            </li> -->
        <!-- <li>
                <router-link to='/worker'>
                <el-icon><User /></el-icon>
                <div class="title">Рабочие</div>
                </router-link>
            </li>
            <li>
                <router-link to='/salary'>
                <el-icon><Money /></el-icon>
                <div class="title">Зарплата</div>
                </router-link>
            </li> -->
      </ul>

      <td valign="top"></td>
    </div>
  </header>
</template>

<script>
import { DataAnalysis } from "@element-plus/icons-vue";

export default {
  props: ["menu"],
  data() {
    return {
      look: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },

  components: { DataAnalysis },
};
</script>

<style lang="scss">
@import "@/styles/part/header.scss";
</style>
